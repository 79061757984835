.toaster {
  background-color: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 2rem;
  position: fixed;
  width: 25rem;
  left: 2rem;
  bottom: 2rem;
  opacity: 0;
  transform: translateX(-2rem);  
  transition: transform 300ms ease, opacity 300ms ease;
  pointer-events: none;
  z-index: 999;
}

.toaster.--success {
  border-top: 5px solid #3BDDAD;
}

.toaster.--error {
  border-top: 5px solid #F22727;
}

.toaster.--active {
  opacity: 1;
  transform: translateX(0rem);
}

.toaster__message {
  font-size: 1.8rem;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .toaster {
    top: 2rem;
    left: calc(50% - 12.5rem);
    bottom: unset;
    transform: translateY(-5px);
  }
  .toaster.--active {
    transform: translateY(0px);
  }  
}