.ploomes-external-form {
  background-color: transparent;
  align-items: flex-start;
  justify-content: center;
  padding: 5rem 2rem;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vw;  
}

@media screen and (max-width: 1024px) {
  .ploomes-external-form {
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

.ploomes-external-form__not-found {
  color: #555;
  font-weight: 400;
  font-size: 4rem;
  max-width: 70rem;
  text-align: center;
}

.--zero-padding {
  padding: 0;
}