.form-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 40rem;
}

.form-info__company-logo {
  max-height: 20rem;
  max-width: 100%;
}

.form-info_form__title {
  font-weight: 500;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  max-width: 75%;
  text-align: left;
  word-break: break-word;
}

.form-info_form__desc {
  margin: 0;
  max-width: 75%;
}

.form-info_form__desc * {
  font-size: 1.8rem;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .form-info {
    align-items: center;
    width: 100%;
  }

  .form-info_form__title {
    text-align: center;
  }

  .form-info_form__desc * {
    text-align: center;
  }
}