.form-content__upload {
  max-width: 10.8rem;
}

@media screen and (max-width: 1024px) {
  .form-content__upload {
    position: relative;
    max-width: 100%;
    height: fit-content;
  }
}

.form-content__upload__inputs {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .form-content__upload__inputs {
    flex-direction: column;
    gap: .5rem;
  } 
}

.form-content__upload__inputs > .react-select-select {
  width: 100%;
}

.form-content__upload__inputs .react-select__control {
  background-color: #f9f9f9;
  border-radius: 4px 0 0 4px !important;
  box-shadow: none !important;
}

.form-content__upload::-webkit-file-upload-button {
  background-color: #0080ff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  outline: transparent;
  padding: .8rem 1rem;
  text-align: center;
  min-height: 38px;
}
@media screen and (max-width: 1024px) {
  .form-content__upload__label {
    border-radius: 4px !important;
  }
}

.form-content__upload {
  visibility: hidden;

}

.form-content__upload::-webkit-file-upload-button {
  visibility: hidden;
}

.form-content__upload__label {
  background-color: #0080ff;
  border: 0;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  outline: transparent;
  padding: 1.2rem 1rem;
  text-align: center;
  visibility: visible;
  white-space: nowrap;
}

.form-content__upload__label:hover {
  background-color: #1a94ff;
}
.form-content__upload__label:active {
  background-color: #1a94ff;
}

input[type='file'] {
  display: none;
}