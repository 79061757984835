.form-content__color-picker {
  position: relative;
}

.form-content__color-picker__button {
  border-radius: 3px;
  border: 1px solid #e2e2e2;
  box-shadow: none;
  cursor: pointer;
  display: block;
  height: 4rem;
  outline: transparent;
  padding: .5rem;
  position: relative;
  width: 100%;
  z-index: 0;
}

.form-content__color-picker__button:focus {
  border: 1px solid #0080ff;
  border-radius: 3px;
}

.form-content__color-picker__button:hover, .form-content__color-picker__button.--active {
  background-color: #f8f8f8;
}

.form-content__color-picker__button.--active {
  border-color: #1a94ff;
  z-index: 99;
}

.form-content__color-picker__button__inside {
  width: 100%;
  height: 100%;
}

.form-content__color-picker-float {
  position: absolute;
  top: 4.5rem;
  z-index: 99;
}

.form-content__color-backdrop {
  background-color: rgba(0,0,0, .5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}