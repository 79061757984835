.react-datepicker__triangle {
  left: 25% !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text,
.react-datepicker__time-list-item,
.react-datepicker__current-month,
.react-datepicker-time__header {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.4rem !important;
  margin: .5rem !important;
}

.react-datepicker__navigation {
  top: 1.7rem !important;
}

.react-datepicker-wrapper {
  width: 100%;
  z-index: 99;
}
.react-datepicker-wrapper input.react-datepicker-ignore-onclickoutside {
  border-color: #1a94ff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.2rem !important;
}