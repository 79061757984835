.ploomes-float {
  background-color: rgba(0,0,0,0.2);
  border-radius: 3px;
  color: #fff;
  padding: 1.5rem;
  position: fixed;
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 10px;
  right: 2rem;
  bottom: 1rem;
}

.ploomes-float:hover {
  background-color: rgba(0,0,0,0.4);
}

.ploomes-float__logo {
  width: 150px;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .ploomes-float {
    position: static;
    margin-bottom: 2rem;
  }
}