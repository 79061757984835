.form-content {
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2rem;
  padding: 3rem;
  max-width: 70rem;
  width: 70rem;
  min-width: 70rem;
  align-items: center;
  margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
  .form-content {
    margin: 2rem 0 0 0;
    max-width: unset;
    min-width: unset;
    width: 80%;
  }
  .form-content.--no-container {
    width: 100%;
  }
}

.form-content__item {
  padding: 0 1.5rem;
  width: 100%;
}

font {
  font-size: inherit;
}

.form-content__input, .form-content__textarea, .react-datepicker-wrapper input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  box-shadow: none;
  color: #555;
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  height: 4rem;
  line-height: 1.5;
  outline: transparent;
  padding: .5rem 1rem;
  width: 100% !important;  
}

.form-content__input:focus, .form-content__textarea:focus, .form-content__select:focus {
  border-color: #0080ff;
}

.form-content__button {
  border: 0;
  border-radius: 5px;
  background-color: #0080ff;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  outline: transparent;
  padding: 1.6rem 1.5rem;
  text-align: center;
  transition: width 150ms ease-in-out;
  width: 100%;
  will-change: width;
  margin-bottom: 1rem;
}

.form-content__button.--disabled {
  background-color: #a8a8a8;
}

.form-content__button:hover:not(.--disabled), .form-content__upload button:hover {
  background-color: #1a94ff;
  cursor: pointer;
}

.form-content__label, .form-content__desc, .form-content__validation {
  font-weight: 400;
  margin: 0 0 .5rem 0;
}

.form-content__label {
  font-size: 1.6rem;
}

.form-content__desc {
  align-self: flex-start;
  color: #979797;
  font-size: 1.3rem;
}

.form-content__validation {
  color: #f22727;
  font-size: 1.3rem;
}

.form-content__input:focus.--invalid, .form-content__input.--invalid {
  border-color: #f22727;
}

.--no-container {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}