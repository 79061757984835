.react-select__menu-list, .react-select__single-value, .react-select__multi-value, .react-select__placeholder, .react-select__menu-notice {
  font-size: 1.8rem !important;
}

.react-select__control {
  border: 1px solid #e2e2e2 !important;
}

.react-select__input {
  color: #555;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.5;  
  width: 100%;
}

.react-select__multi-value__remove {
  cursor: pointer;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #bfc2cb;
  border-radius: 1em;
}

.react-select__menu-list::-webkit-scrollbar {
  height: .7em;
  width: .5em;
}

.react-select__dropdown-indicator, .react-select__indicator-separator {
  display: none !important;
}

.react-select__indicator, .react-select__option {
  cursor: pointer !important;
}

.react-select__value-container {
  min-height: 3rem;
  cursor: default;
}