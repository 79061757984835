.loading {
  align-items: center;
  background-color: #eee;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, visibility 0ms ease 300ms;
  position: fixed;
}

.loading.--active {
  opacity: 1;
  visibility: visible;
}

.loading-svg {
  animation: rotate 2s linear infinite;
  height: 20rem;
  transform-origin: center center;
  width: 20rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .5;
  margin: auto;
}

.loading-svg-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}