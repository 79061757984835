.loading-dots {
  justify-content: center;
  gap: .25rem;
  text-align: center;
  display: flex;
}

.loading-dots__dot {
  width: 1.6rem;
  height: 1.6rem;
  background-color: #ffffff;
  border-radius: 100%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loading-dots__dot:nth-of-type(1) {
  animation-delay: -0.32s;
}

.loading-dots__dot:nth-of-type(2) {
  animation-delay: -0.16s; 
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}