.form-content__phone-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-content__phone-row {
  display: flex;
  align-items: center;
}

.form-content__phone-btn {
  align-items: center;
  background-color: #aeaeae;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 1.8rem;
  justify-content: center;
  width: 1.8rem;
}

.form-content__phone-btn.--minus {
  padding-bottom: 2px;
}

.form-content__phone-row > input {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.form-content__phone-selector {
  align-items: center;
  display: flex;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  height: 4rem;
  justify-content: center;
  position: relative;
  width: 4rem;
}

.form-content__phone-selector.--left {
  border-right: 0;
  border-radius: .3rem;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.form-content__phone-selector.--right {
  border-left: 0;
  border-radius: .3rem;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  width: 12rem;
}

.form-content__phone-selector > img {
  width: 2rem;
}

.form-content__phone-selector__dropdown {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: .3rem;
  box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
  left: 0;
  position: absolute;
  top: 4rem;
  z-index: 999;
}

.form-content__phone-selector__dropdown > ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  width: 16rem;
}

.form-content__phone-selector__dropdown li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
  font-size: 14px;
}

.form-content__phone-selector__dropdown li:hover {
  background-color: #ccc;
}

.form-content__phone-selector__dropdown img {
  width: 2rem;
}

.form-content__phone-selector__blank {
  background-color: #aeaeae;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.form-content__phone-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  gap: .5rem;
  padding: 0 1rem;
  padding-right: 0;
}

.form-content__phone-type {
  font-size: 1.4rem;
}

@media screen and (max-width: 1024px) {
  .form-content__phone-selector > img {
    width: 1.5rem;
  }

  .form-content__phone-type {
    font-size: 1.2rem;
  }
}