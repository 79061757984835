* {
  box-sizing: border-box;
  font-size: 10px;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 1200px) {
  * {
    font-size: 9px;
  }
}

body {
  background-color: transparent;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-thumb {
  background-color: #bfc2cb;
  border-radius: 1em;
}

body::-webkit-scrollbar {
  width: .5em;
  height: .7em;
}
